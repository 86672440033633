<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit" enctype="multipart/form-data">
      <v-container>
        <v-row>
          <v-col>
<!--            <validation-provider v-slot="{ errors }" name="Identifier" rules="required"-->
<!--              ref="tenantIdentificationValidator">-->
<!--              <v-text-field dense outlined :disabled="loadings.formBtn" v-model="tenant_identification"-->
<!--                :error-messages="errors" label="Tenant ID" required @blur="isTenantIdentificationValid()"></v-text-field>-->
<!--            </validation-provider>-->
            <validation-provider v-slot="{ errors }" name="Domain" :rules="{
                required: true,
              }">
              <v-text-field
                  dense outlined
                  :readonly="$route.params.tenant_id || $route.params.supplier_id"
                  v-model="domain"
                  :error-messages="errors"
                  v-mask="'A'.repeat(100)"
                  label="Domain" required>
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors }" name="Tenant Name" :rules="{
                required: true,
              }">
              <v-text-field dense outlined v-model="name" :error-messages="errors"
                label="Tenant Name" required></v-text-field>
            </validation-provider>

          </v-col>
          <v-col>
            <validation-provider v-slot="{ errors }" name="EIN" >
              <v-text-field
                  dense
                  outlined
                  v-model="ein"
                  :error-messages="errors"
                  label="EIN">
              </v-text-field>
            </validation-provider>

            <validation-provider v-slot="{ errors }" name="Merchant Registation" >
              <v-text-field
                  dense
                  outlined
                  v-model="merchant_registration"
                  :error-messages="errors"
                  label="Merchant Registation">
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="text-h6">Logo</p>
            <file-input-with-preview v-model="logo" accepted-file-types="image/*" icon="mdi-image"
              :preview-urls="logoUrl" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="mr-4" type="submit" :disabled="invalid">
              {{ actionBtnText }}
            </v-btn>
            <v-btn @click="clear"> clear </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </validation-observer>
</template>

<script>
import { required, digits, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapActions, mapGetters } from "vuex";
// import stripeApi from "@/api/stripe";
import tenantApi from "@/api/tenant";
import FileInputWithPreview from "../common/FileInputWithPreview.vue"

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} not is valid",
});

export default {
  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: {
    ValidationProvider,
    ValidationObserver,
    FileInputWithPreview
  },

  /************************************************************************************
   *                                    PROPS
   * **********************************************************************************/
  props: ['type'],

  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data: () => ({
    loadings: {
      formBtn: false,
    },
    logoUrl: ""
  }),

  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed: {
    ...mapGetters(["currentTenant", "tenantFormData"]),

    actionBtnText() {
      let text = "";
      const param = this.$route.params;

      text = param.tenant_id || param.supplier_id ? "update" : "create";

      return text;
    },

    tenant_identification: {
      get() {
        return this.$store.getters["tenantFormData"]["tenant_identification"];
      },

      set(value) {
        let data = {
          key: "tenant_identification",
          data: value,
        };

        this.$store.commit("setTenantsFrmData", data);
      },
    },

    domain: {
      get() {
        return this.$store.getters["tenantFormData"]["domain"];
      },

      set(value) {
        let data = {
          key: "domain",
          data: value.toLowerCase(),
        };

        this.$store.commit("setTenantsFrmData", data);

        //sets the tenant_identifiction to the same value as the domain
        data.key = "tenant_identification";
        this.$store.commit("setTenantsFrmData", data);
      },
    },

    logo: {
      get() {
        return this.$store.getters["tenantFormData"]["logo"];
      },

      set(value) {
        let data = {
          key: "logo",
          data: value,
        };

        this.$store.commit("setTenantsFrmData", data);
      },
    },

    name: {
      get() {
        return this.$store.getters["tenantFormData"]["name"];
      },

      set(value) {
        let data = {
          key: "name",
          data: value,
        };

        this.$store.commit("setTenantsFrmData", data);
      },
    },

    ein: {
      get() {
        return this.$store.getters["tenantFormData"]["ein"];
      },

      set(value) {
        let data = {
          key: "ein",
          data: value,
        };

        this.$store.commit("setTenantsFrmData", data);
      },
    },

    merchant_registration: {
      get() {
        return this.$store.getters["tenantFormData"]["merchant_registration"];
      },

      set(value) {
        let data = {
          key: "merchant_registration",
          data: value,
        };

        this.$store.commit("setTenantsFrmData", data);
      },
    },
  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions([
      "createTenant",
      "getTenantsById",
      "clearTenantForm",
      "updateTenant",
      "showSubscriptionMessage"
    ]),

    submit() {
      const valid = this.$refs.observer.validate();
      /* Check if create or update */
      let action = window.location.pathname === "/tenant/manage" ? "Creating" : "Updating";
      if (!valid) return;
      this.showNotification({
        type: 3,
        text: `${action} Tenant`,
        persistent: true,
      });

      if (this.type == "supplier") {
        let data = {
          key: "is_supplier",
          data: true,
        };

        this.$store.commit("setTenantsFrmData", data);
      }

      if (this.currentTenant && this.currentTenant.id) {
        /* Update */
        this.tenantFormData['id'] = this.currentTenant.id;

        this.updateTenant(this.tenantFormData)
          .then((suss) => {
            this.showNotification({
              type: 1,
              text: suss.message,
              lngKey: false,
            });
          }).catch((err) => {
            this.$swal.fire({
                timer: 4000,
                icon: "error",
                title: "Tenant Update Failed",
                text: err,
                toast: true,
                position: "bottom-end",
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: true,
            });
          }).finally(() => {
            this.closeNotifications(3);
          });
      } else {
        /* Create */
        if (this.tenantFormData.merchant_registration === undefined) {
          this.tenantFormData.merchant_registration = '';
        }

        if (this.tenantFormData.ein === undefined) {
          this.tenantFormData.ein = '';
        }

        this.createTenant(this.tenantFormData)
          .then((suss) => {
            this.showNotification({
              type: 1,
              text: suss.message,
              lngKey: false,
            });

            this.$nextTick(() => {
              if (this.type == 'supplier') {
                this.$router.push({ path: `/supplier/manage/${suss.data.id}` })
              } else {
                this.$router.push({ path: `/tenant/manage/${suss.data.id}` })
              }
            });

            this.$store.commit("setCurrentTenant", suss.data);
          }).catch((err) => {
            this.$swal.fire({
              timer: 4000,
              icon: "error",
              title: err.statusText,
              text: err.data.message || "Error trying to store Tenant",
            });
          }).finally(() => {
            this.closeNotifications(3);
          });
      }
    },

    clear() {
      this.clearTenantForm();
      this.$refs.observer.reset();
      const param = this.$route.params;

      if (param.tenant_id) {
        this.$router.push({ path: "/tenant/manage" });
      }
    },

    async checkTenantIdentificationExist(tenantIdentification) {
      const { data } = await tenantApi.checkTenantIdentificationExist(tenantIdentification);

      return data;
    },

    isTenantIdentificationValid() {
      if (this.tenant_identification === undefined || this.tenant_identification == "") {
        return; //dont call api
      }

      this.checkTenantIdentificationExist(this.tenant_identification).then((result) => {
        if (result.tenantIdentificationExist === true) {
          this.$swal.fire({
            timer: 4000,
            icon: "warning",
            title: "Sorry",
            text: "The entered Tenant Id is already in use",
          });

          this.$refs.tenantIdentificationValidator.setErrors(["Tenant Id already in use"]);
        }
      }).catch(() => {
        this.$swal.fire({
          timer: 4000,
          icon: "error",
          title: "Sorry",
          text: "An error occurred during the tenant identification validation",
        });
      });
    },

    initializeFormData() {
      this.$store.commit('setCurrentTenant', {});
      this.$store.commit("setCurrentTenantFormData", {});
      this.$store.commit('setSupplierFormDataAll', {
        entity_name: null,
        phone_number: null,
        point_of_contact: null,
        email: null,
        description: null,
        postal_address: null,
        physical_address: null,
        postal_city: null,
        physical_city: null,
        postal_zipcode: null,
        physical_zipcode: null,
        image_path: null,
        is_active: null,
      });
    }
  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {
    currentTenant(tenant = {}) {
      const param = this.$route.params;

      if (tenant.id && (param.tenant_id || param.supplier_id)) {
        const tenant = this.currentTenant;
        this.logoUrl = `${process.env.VUE_APP_AWS_ENDPOINT}${tenant.logo}`;

        if (tenant.id) {
          this.$store.commit("setCurrentTenantFormData", tenant);

          if (tenant.is_supplier) {
            if (tenant.supplier) {
              this.$store.commit('setSupplierFormDataAll', tenant.supplier);
            } else {
              this.$store.commit('setSupplierFormDataAll', {
                entity_name: null,
                phone_number: null,
                point_of_contact: null,
                email: null,
                description: null,
                postal_address: null,
                physical_address: null,
                postal_city: null,
                physical_city: null,
                postal_zipcode: null,
                physical_zipcode: null,
                image_path: null,
                is_active: null,
              });
            }
          }
        }
      }
    },
  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {
    const param = this.$route.params;
    if (this.type === 'supplier') {
      let data = {
        key: "is_supplier",
        data: true,
      };

      this.$store.commit("setTenantsFrmData", data);
    }

    if (!param.tenant_id && !param.supplier_id) {
      this.$store.commit("clearTenantFrmData");
    }
  },

  /************************************************************************************
   *                                  MOUNTED
   * **********************************************************************************/
  mounted() {
    let route = this.$route;
    let router = this.$router;
    let swal = this.$swal;
    let data = { route, swal, router };

    this.showSubscriptionMessage(data);


    if (!this.$route.params.tenant_id) {
      this.initializeFormData();
    }
  }
};
</script>